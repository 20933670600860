<template>
    <div class="init_">
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="350">
            <el-table-column prop="itemName" :label="$t('withdrawNft.name11')" />
            <el-table-column prop="qty" :label="$t('nfts.name19')" width="100" />
            <el-table-column prop="nftOwnerAddr" label="Owner" />
            <el-table-column prop="nftOwnerType" :label="$t('nfts.name3')" width="120" >
                <template #default="scope">
                    {{arrayText[scope.row.nftOwnerType]}}
                </template>
            </el-table-column>
            <el-table-column prop="storageWalletAddr" :label="$t('nfts.name5')" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineExpose,getCurrentInstance } from 'vue'
    import mixins from '@/views/mixins/page'
    import fromSearch from '@/components/fromSearch.vue'
    import { own_ } from '@/const/from/nft/work'
    import pageCom from '@/components/pageCom.vue'
    const { $t } = getCurrentInstance().proxy;
    
    const object_ = ref({});object_.value = own_
    let pageObj = mixins(['getNftiteminfo'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const checkItem = ref(false)
    const arrayText = ['',$t('nfts.name9'),$t('nfts.name8')]

    const init_ = (obj)=>{
        checkItem.value = false
        updateOtherParm(obj)
        getList()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
</style>